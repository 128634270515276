import Highway from '@dogstudio/highway';

import { constants } from '../store';

import CaseList from '../components/case_list/case_list';
import CaseLongImage from '../components/case_long_image/case_long_image';
import Cases from '../components/cases/cases';
import CaseHeader from '../components/header/case-header';
import ImageReel from '../components/image_reel/image_reel';
import CaseVideo from '../utils/case-video';
import CaseGrid from '../components/case_image-video_grid/case_image-video_grid';
import ImageScale from '../components/image_scale/image_scale';
import ImageGrid from '../components/image_grid/image_grid';
import Grid from '../components/grid/grid';
import List from '../components/list/list';
import NewsGrid from '../components/news_grid/news_grid';
import Logos from '../components/logos/logos';
import Video from '../components/video/video';
import Vimeo from '../components/vimeo/vimeo';
import SocialCarousel from '../components/social_carousel/social_carousel';
import StickyCta from '../components/header/sticky-cta';

let caseList;
let caseListEl;
let cases;
let casesEl;
let grid;
let gridEl;
let list;
let listEl;
let caseHeader;
let newsGrid;
let newsGridEl;
let stickyCta;
let stickyCtaEl;

let caseGridEls;
const caseGrids = [];

let imageScaleEls;
const imageScales = [];

let imageGridEls;
const imageGrids = [];

let logoEls;
const logos = [];

let caseVideoEls;
const caseVideos = [];

let caseLongImageEls;
const caseLongImages = [];

let videoEls;
const videos = [];

let vimeoEls;
const vimeos = [];

let imageReelEls;
const imageReels = [];

let socialCarouselEls;
const socialCarousels = [];

class PageRenderer extends Highway.Renderer {
  onEnter() {}

  onEnterCompleted() {
    caseGridEls = document.querySelectorAll('.js-case-grid');
    [...caseGridEls].forEach((el) => {
      const caseGrid = new CaseGrid(el);
      caseGrid.init();

      caseGrids.push(caseGrid);
    });

    imageScaleEls = document.querySelectorAll('.js-image-scale');
    [...imageScaleEls].forEach((el) => {
      const imageScale = new ImageScale(el);
      imageScale.init();

      imageScales.push(imageScale);
    });

    imageReelEls = document.querySelectorAll('.js-image-reel');
    [...imageReelEls].forEach((el) => {
      const imageReel = new ImageReel(el);
      imageReel.init();

      imageReels.push(imageReel);
    });

    imageGridEls = document.querySelectorAll('.js-image-grid');
    [...imageGridEls].forEach((el) => {
      const imageGrid = new ImageGrid(el);
      imageGrid.init();

      imageGrids.push(imageGrid);
    });

    logoEls = document.querySelectorAll('.js-logos');
    [...logoEls].forEach((el) => {
      const logo = new Logos(el);
      logo.init();

      logos.push(logo);
    });

    videoEls = document.querySelectorAll('.js-video');
    [...videoEls].forEach((el) => {
      const video = new Video(el);
      video.init();

      videos.push(video);
    });

    socialCarouselEls = document.querySelectorAll('.js-social-carousel');
    [...socialCarouselEls].forEach((el) => {
      const socialCarousel = new SocialCarousel(el);
      socialCarousel.init();

      socialCarousels.push(socialCarousel);
    });

    vimeoEls = document.querySelectorAll('.js-vimeo');
    [...vimeoEls].forEach((el) => {
      const vimeo = new Vimeo(el);
      vimeo.init();

      vimeos.push(vimeo);
    });

    caseVideoEls = document.querySelectorAll('.js-case-video');
    [...caseVideoEls].forEach((el) => {
      const video = new CaseVideo(el);
      video.init();

      caseVideos.push(video);
    });

    caseLongImageEls = document.querySelectorAll('.js-case-long-image');
    [...caseLongImageEls].forEach((el) => {
      const image = new CaseLongImage(el);
      image.init();

      caseLongImages.push(image);
    });

    caseListEl = document.querySelector('.js-case-list');
    if (caseListEl) {
      caseList = new CaseList();
      caseList.init();
    }

    casesEl = document.querySelector('.js-cases');
    if (casesEl) {
      cases = new Cases();
      cases.init();
    }

    gridEl = document.querySelector('.js-grid');
    if (gridEl) {
      grid = new Grid();
      grid.init();
    }

    listEl = document.querySelector('.js-list');
    if (listEl) {
      list = new List();
      list.init();
    }

    stickyCtaEl = document.querySelector('.js-sticky');
    if (stickyCtaEl) {
      stickyCta = new StickyCta();
      stickyCta.init();
    }

    newsGridEl = document.querySelector('.js-news-grid');
    if (newsGridEl) {
      newsGrid = new NewsGrid();
      newsGrid.init();
    }

    if (constants.headerLayout === 'case') {
      caseHeader = new CaseHeader();
      caseHeader.init();
    }
  }

  onLeave() {
    imageGrids.forEach((imgGrid) => {
      imgGrid.destroy();
      imgGrid = undefined;
    });
    imageGrids.length = 0;

    caseVideos.forEach((video) => {
      video.destroy();
      video = undefined;
    });
    caseVideos.length = 0;

    videos.forEach((video) => {
      video.destroy();
      video = undefined;
    });
    videos.length = 0;

    socialCarousels.forEach((carousel) => {
      carousel.destroy();
      carousel = undefined;
    });
    socialCarousels.length = 0;

    imageReels.forEach((reel) => {
      reel.destroy();
      reel = undefined;
    });
    imageReels.length = 0;

    if (casesEl) {
      cases.destroy();
      cases = undefined;
    }

    if (caseHeader) {
      caseHeader.destroy();
      caseHeader = undefined;
    }

    if (gridEl) {
      grid.destroy();
      grid = undefined;
    }

    if (listEl) {
      list.destroy();
      list = undefined;
    }

    if (stickyCtaEl) {
      stickyCta.destroy();
      stickyCta = undefined;
    }

    if (newsGridEl) {
      newsGrid.destroy();
      newsGrid = undefined;
    }
  }

  onLeaveCompleted() {}
}

export default PageRenderer;
