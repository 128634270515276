import { constants } from '../../store';

class ImageReel {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-image-reel');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');

    this.state = {
      interval: null,
      currentIndex: 0,
      intervalSpeed: {
        current: 1500,
        min: 300,
        max: 1500,
        step: 50, // Incrementally adjust by this step
        adjustmentFactor: 1.2, // Factor for dynamic adjustments
        adjustmentInterval: 500 // Time interval for speed adjustments
      },
      hovering: false,
      speedInterval: null // To manage gradual speed changes
    };
  }

  startLoop() {
    clearInterval(this.state.interval); // Ensure no overlapping intervals

    this.state.interval = setInterval(() => {
      this.dom.items.forEach((item, index) => {
        item.style.zIndex = index === this.state.currentIndex ? 1 : 0;
      });

      this.state.currentIndex = (this.state.currentIndex + 1) % this.dom.items.length;
    }, this.state.intervalSpeed.current);
  }

  adjustSpeed() {
    clearInterval(this.state.speedInterval);

    this.state.speedInterval = setInterval(() => {
      const targetSpeed = this.state.hovering
        ? this.state.intervalSpeed.min
        : this.state.intervalSpeed.max;

      if (this.state.intervalSpeed.current === targetSpeed) {
        clearInterval(this.state.speedInterval);
        return;
      }

      const difference = Math.abs(this.state.intervalSpeed.current - targetSpeed);
      const dynamicStep = Math.max(
        this.state.intervalSpeed.step,
        difference / this.state.intervalSpeed.adjustmentFactor
      );

      if (this.state.intervalSpeed.current < targetSpeed) {
        this.state.intervalSpeed.current = Math.min(
          this.state.intervalSpeed.current + dynamicStep,
          targetSpeed
        );
      } else {
        this.state.intervalSpeed.current = Math.max(
          this.state.intervalSpeed.current - dynamicStep,
          targetSpeed
        );
      }

      this.startLoop(); // Restart loop with the new speed
    }, this.state.intervalSpeed.adjustmentInterval);
  }

  changeHoverState(hovering) {
    this.state.hovering = hovering;
    this.adjustSpeed();
  }

  addEventListeners() {
    this.dom.el.addEventListener('mouseenter', () => this.changeHoverState(true));
    this.dom.el.addEventListener('mouseleave', () => this.changeHoverState(false));
  }

  removeEventListeners() {
    this.dom.el.removeEventListener('mouseenter', () => this.changeHoverState(true));
    this.dom.el.removeEventListener('mouseleave', () => this.changeHoverState(false));
  }

  init() {
    this.startLoop();

    if (constants.isDevice) return;
    this.addEventListeners();
  }

  destroy() {
    clearInterval(this.state.interval);
    clearInterval(this.state.speedInterval);

    if (constants.isDevice) return;
    this.removeEventListeners();
  }
}

export default ImageReel;
