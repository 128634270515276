class CookieManager {
  constructor() {
    // Show the cookie banner if not previously closed
    window.addEventListener('load', () => {
      if (!this.getCookie('cookies_clicked')) {
        const cookieBanner = document.querySelector('.cookie');
        if (cookieBanner) {
          cookieBanner.style.display = 'block';
        }
      }

      if (this.getCookie('cookies_accepted')) {
        // Update Google Consent Mode
        if (window.gtag) {
          window.gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
          });
        }
      }
    });

    // Accept
    const acceptBtn = document.querySelector('.accept-cookies');
    if (acceptBtn) {
      acceptBtn.addEventListener('click', () => this.acceptCookies());
    }

    // Decline
    const declineBtn = document.querySelector('.decline-cookies');
    if (declineBtn) {
      declineBtn.addEventListener('click', () => this.declineCookies());
    }
  }

  /**
   * Sets a cookie with given name, value, and expiration in days.
   */
  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  /**
   * Retrieves a cookie by name, returns the value or null.
   */
  getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  /**
   * User accepts cookies:
   * - Set cookie to remember the choice
   * - Update Google Consent Mode to "granted"
   */
  acceptCookies() {
    this.setCookie('cookies_clicked', 'true', 14);
    this.setCookie('cookies_accepted', 'true', 14);

    const cookieBanner = document.querySelector('.cookie');
    if (cookieBanner) {
      cookieBanner.style.display = 'none';
    }

    // Update Google Consent Mode
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
      });
    }

    // (Optional) Send a dataLayer event
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'cookies_accepted' });
    }
  }

  /**
   * User declines cookies:
   * - Set cookie to remember the choice
   * - Update Google Consent Mode to "denied"
   * - (Optional) Revoke FB Pixel consent
   */
  declineCookies() {
    this.setCookie('cookies_clicked', 'true', 1);

    const cookieBanner = document.querySelector('.cookie');
    if (cookieBanner) {
      cookieBanner.style.display = 'none';
    }

    // Update Google Consent Mode
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      });
    }

    // Revoke FB Pixel consent (if used)
    if (window.fbq) {
      window.fbq('consent', 'revoke');
    }

    // (Optional) Track an event for your own analytics
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'cookies_declined' });
    }
  }
}

export default CookieManager;
