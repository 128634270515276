import { instances } from '../../store';

class StickyCta {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-sticky');
    this.dom.sections = document.querySelectorAll('.c.js-form');
  }

  init() {
    this.dom.el.addEventListener('click', this.handleClick);
  }

  handleClick = () => {
    // Get data-anchor from the .js-sticky element
    const anchor = this.dom.el.dataset.anchor;
    if (!anchor) return; // If no data-anchor, do nothing

    // Convert NodeList to array so we can use array methods more easily
    const sectionsArray = [...this.dom.sections];

    // Find the first section whose id matches the data-anchor
    const matchedSection = sectionsArray.find((section) => {
      return section.id && section.id === anchor;
    });

    console.log(matchedSection);

    if (matchedSection) {
      // Scroll to the matched section
      instances.scroll.scrollToSection(matchedSection);
    }
  };

  destroy() {
    this.dom.el.removeEventListener('click', this.handleClick);
  }
}

export default StickyCta;
